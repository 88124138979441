import React, { useMemo, useState } from 'react'
import { Button, OverlayTrigger } from 'react-bootstrap'
import { $getSelection } from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useParams } from 'react-router-dom'

import { useAppState, useFocused, useSurvey } from 'hooks'
import {
  placeholderStandardFields,
  isPlaceholderInUse,
  getFormattedPlaceholderText,
  STATES,
} from 'helpers'

export const PlaceholderMenu = () => {
  const [editor] = useLexicalComposerContext()
  const [showPlaceholderMenu, setShowPlaceholderMenu] = useState(false)
  const [language] = useAppState(STATES.SURVEY_LANGUAGE, 'en')
  const { surveyId } = useParams()
  const { survey } = useSurvey(surveyId)
  const { groupIndex, questionIndex } = useFocused()

  const getCurrentUpdatedFocusedQuestion = () => {
    const currentGroup = survey?.questionGroups?.[groupIndex]
    return currentGroup?.questions?.[questionIndex] || null
  }

  const handlePlaceholderField = (placeholder) => {
    editor.update(() => {
      const selection = $getSelection()
      selection.insertText(`{${placeholder}}` + ' ')
    })

    setShowPlaceholderMenu(false)
  }

  const renderPlaceholderButton = (
    key,
    value,
    onClickHandler = () => {},
    selected = false,
    customStyle = {}
  ) => (
    <Button
      variant={`${selected ? 'outline-primary' : 'primary'}`}
      key={key}
      className={`btn ${selected ? 'btn-outline-primary' : 'btn-primary'} text-truncate text-start d-block fw-normal mr-2 mt-2 mb-2`}
      onClick={onClickHandler}
      style={customStyle}
    >
      {value}
    </Button>
  )

  const generatePlaceholderButton = (
    groupIndex,
    questionIndex,
    question,
    subQuestion = null,
    subQuestionIndex = null
  ) => {
    const buttonId = subQuestion
      ? `${groupIndex}-${questionIndex}-${subQuestionIndex}`
      : `${groupIndex}-${questionIndex}`
    const placeholder = getFormattedPlaceholderText(
      language,
      question,
      subQuestion
    )
    const fieldId = subQuestion
      ? `${question.title}_${subQuestion.title}`
      : `${question.title}`

    return renderPlaceholderButton(
      buttonId,
      placeholder,
      () => handlePlaceholderField(fieldId + '.shown'),
      isPlaceholderInUse(language, getCurrentUpdatedFocusedQuestion(), fieldId),
      { width: '280px' }
    )
  }

  const hasPreviousQuestions = (group, question) => {
    const isBeforeGroup = group.groupOrder - 1 < groupIndex
    const isBeforeQuestionInGroup = question.questionOrder - 1 < questionIndex
    const isBeforeOrSameGroup = group.groupOrder - 1 <= groupIndex

    return isBeforeGroup || (isBeforeQuestionInGroup && isBeforeOrSameGroup)
  }

  const previousQuestions = useMemo(() => {
    const questions = []
    if (Array.isArray(survey.questionGroups)) {
      survey.questionGroups.forEach((group, groupIndex) => {
        if (Array.isArray(group.questions)) {
          group.questions.forEach((question, questionIndex) => {
            if (!question || !hasPreviousQuestions(group, question)) return
            if (question.sid && Array.isArray(question.subquestions)) {
              Object.entries(question.subquestions).forEach(
                ([subQuestionIndex, subQuestion]) => {
                  questions.push(
                    generatePlaceholderButton(
                      groupIndex,
                      questionIndex,
                      question,
                      subQuestion,
                      subQuestionIndex
                    )
                  )
                }
              )
            } else {
              questions.push(
                generatePlaceholderButton(groupIndex, questionIndex, question)
              )
            }
          })
        }
      })
    }
    return { questions }
  }, [survey, groupIndex, questionIndex])

  const renderStandardFields = useMemo(() => {
    return Object.keys(placeholderStandardFields).map((fieldKey, index) =>
      renderPlaceholderButton(
        index,
        placeholderStandardFields[fieldKey],
        () =>
          handlePlaceholderField(index > 2 ? fieldKey : 'TOKEN:' + fieldKey),
        isPlaceholderInUse(
          language,
          getCurrentUpdatedFocusedQuestion(),
          fieldKey
        )
      )
    )
  }, [survey, groupIndex, questionIndex])

  const placeholderFieldsMenuContent = (
    <div className="content-editor-placeholder-menu">
      <div>
        <p className="small pl-0 pb-3 text-left text-black">Standard fields</p>
        {renderStandardFields}
      </div>
      {groupIndex > 0 || questionIndex > 0 ? (
        <>
          <hr className="mt-4 mb-4" />
          <div>
            <p className="small pl-0 pb-3 text-left text-black">
              Fields based on previous answers
            </p>
            <div className="placeholder-fields-scrollable">
              {previousQuestions.questions}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )

  return (
    <OverlayTrigger
      trigger="click"
      overlay={placeholderFieldsMenuContent}
      placement="right-start"
      show={showPlaceholderMenu}
      onToggle={(show) => {
        setShowPlaceholderMenu(show)
      }}
      rootClose
    >
      <Button
        variant=""
        className="toolbar-button d-flex justify-content-center align-items-center"
      >
        {'{}'}
      </Button>
    </OverlayTrigger>
  )
}
