import React from 'react'

import { QuestionTypeSelector } from 'components/QuestionTypeSelector'
import { QuestionTypeInfo } from 'components/QuestionTypes'
import {
  RandomNumber,
  SCALE_2,
  STATES,
  NEW_OBJECT_ID_PREFIX,
  createBufferOperation,
  getAnswerExample,
  getQuestionExample,
} from 'helpers'
import { useAppState, useBuffer, useFocused, useSurvey } from 'hooks'

export const TopBarQuestionInserter = ({ surveyID }) => {
  const [, setIsAddingQuestionOrGroup] = useAppState(
    STATES.IS_ADDING_QUESTION_OR_GROUP,
    false
  )
  const [clickedQuestionGroupIndex, setClickedQuestionGroupIndex] = useAppState(
    STATES.CLICKED_QUESTION_GROUP_INDEX,
    null
  )
  const { survey, update, language } = useSurvey(surveyID)
  const { addToBuffer } = useBuffer()
  const { setFocused, groupIndex } = useFocused()
  const questionGroupToAddQuestion = clickedQuestionGroupIndex
    ? survey?.questionGroups[clickedQuestionGroupIndex]
    : groupIndex
      ? survey?.questionGroups[groupIndex]
      : survey?.questionGroups[0]

  const handleAddQuestionGroup = (newQuestionGroup, index) => {
    if (!survey.questionGroups) {
      survey.questionGroups = []
    }

    const newQuestionGroupIndex = index
      ? index + 1
      : survey.questionGroups.length

    const updatedQuestionGroups = [
      ...survey.questionGroups.slice(0, newQuestionGroupIndex),
      newQuestionGroup,
      ...survey.questionGroups.slice(newQuestionGroupIndex),
    ].map((questionGroup, index) => {
      questionGroup.sortOrder = index + 1
      return questionGroup
    })

    survey.questionGroups = updatedQuestionGroups

    const operation = createBufferOperation(newQuestionGroup.gid)
      .questionGroup()
      .create({
        questionGroup: {
          ...newQuestionGroup,
          sortOrder: 1,
          gRelevance: '',
          sid: surveyID,
          tempId: newQuestionGroup.gid,
        },
        questionGroupL10n: newQuestionGroup.l10ns,
      })

    update({ ...survey })
    addToBuffer(operation)
    setFocused(newQuestionGroup, newQuestionGroupIndex)
  }

  const addQuestionGroup = () => {
    const groupId = RandomNumber()
    const newQuestionGroup = {
      gid: NEW_OBJECT_ID_PREFIX + groupId,
      sid: surveyID,
      type: QuestionTypeInfo.QUESTION_GROUP.type,
      theme: QuestionTypeInfo.QUESTION_GROUP.theme,
      l10ns: {
        [language]: {
          groupName: '',
          description: '',
        },
      },
      questions: [],
    }

    handleAddQuestionGroup(newQuestionGroup)
    setIsAddingQuestionOrGroup(false)
  }

  const handleAddQuestion = (question) => {
    const _groupIndex = clickedQuestionGroupIndex
      ? clickedQuestionGroupIndex
      : groupIndex
        ? groupIndex
        : 0

    const updatedQuestionGroups = [...survey.questionGroups]
    updatedQuestionGroups[_groupIndex] = {
      ...updatedQuestionGroups[_groupIndex],
      questions: [...updatedQuestionGroups[_groupIndex].questions, question],
    }

    survey.questionGroups = updatedQuestionGroups
    update({ ...survey })

    setFocused(
      question,
      _groupIndex,
      updatedQuestionGroups[_groupIndex].questions.length - 1
    )

    setClickedQuestionGroupIndex(null)

    const operation = createBufferOperation(question.qid)
      .question()
      .create({
        question: { ...question, tempId: question.qid },
        questionL10n: { ...question.l10ns },
        attributes: { ...question.attributes },
        answers: { ...question.answers },
        subquestions: { ...question.subquestions },
      })
    addToBuffer(operation)
  }

  const addNewQuestion = (questionTypeInfo) => {
    const questionId = `${NEW_OBJECT_ID_PREFIX}${RandomNumber()}`
    const questionThemeName = questionTypeInfo.questionThemeName

    if (
      (!questionGroupToAddQuestion &&
        questionTypeInfo.type !== QuestionTypeInfo.QUESTION_GROUP.type) ||
      !questionTypeInfo.type
    ) {
      return
    } else if (questionTypeInfo.type === QuestionTypeInfo.QUESTION_GROUP.type) {
      addQuestionGroup()
      return
    }

    const questionWithAnswersTheme = [
      QuestionTypeInfo.SINGLE_CHOICE_LIST_RADIO.theme,
      QuestionTypeInfo.SINGLE_CHOICE_LIST_RADIO_WITH_COMMENT.theme,
      QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.theme,
      QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.theme,
      QuestionTypeInfo.SINGLE_CHOICE_IMAGE_SELECT.theme,
      QuestionTypeInfo.ARRAY.theme,
      QuestionTypeInfo.ARRAY_DUAL_SCALE.theme,
      QuestionTypeInfo.ARRAY_COLUMN.theme,
      QuestionTypeInfo.RANKING.theme,
      QuestionTypeInfo.RANKING_ADVANCED.theme,
    ]

    const questionWithSubquestionsTheme = [
      QuestionTypeInfo.ARRAY_TEXT.theme,
      QuestionTypeInfo.ARRAY.theme,
      QuestionTypeInfo.ARRAY_NUMBERS.theme,
      QuestionTypeInfo.ARRAY_COLUMN.theme,
      QuestionTypeInfo.ARRAY_DUAL_SCALE.theme,
      QuestionTypeInfo.MULTIPLE_CHOICE.theme,
      QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.theme,
      QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.theme,
      QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.theme,
      QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.theme,
      QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.theme,
    ]

    const questionWithAnswersDualScale2Theme = [
      QuestionTypeInfo.ARRAY_DUAL_SCALE.theme,
    ]

    const questionWithSubquestionsDualScale2Theme = [
      QuestionTypeInfo.ARRAY_TEXT.theme,
      QuestionTypeInfo.ARRAY_NUMBERS.theme,
    ]

    let answers = []
    let subQuestions = []

    if (questionWithAnswersTheme.includes(questionThemeName)) {
      answers.push(
        getAnswerExample({ qid: questionId, language, sortOrder: 1 }),
        getAnswerExample({ qid: questionId, language, sortOrder: 2 })
      )
    }

    if (questionWithSubquestionsTheme.includes(questionThemeName)) {
      subQuestions.push(
        getQuestionExample({
          sortOrder: 1,
          language,
          parentQid: questionId,
          type: QuestionTypeInfo.LONG_TEXT.type,
          questionThemeName: QuestionTypeInfo.LONG_TEXT.theme,
          gid: questionGroupToAddQuestion.gid,
          sid: questionGroupToAddQuestion.sid,
        }),
        getQuestionExample({
          sortOrder: 2,
          language,
          parentQid: questionId,
          type: QuestionTypeInfo.LONG_TEXT.type,
          questionThemeName: QuestionTypeInfo.LONG_TEXT.theme,
          gid: questionGroupToAddQuestion.gid,
          sid: questionGroupToAddQuestion.sid,
        })
      )
    }

    if (questionWithAnswersDualScale2Theme.includes(questionThemeName)) {
      answers.push(
        getAnswerExample({
          qid: questionId,
          language,
          sortOrder: 3,
          scaleId: SCALE_2,
        }),
        getAnswerExample({
          qid: questionId,
          language,
          sortOrder: 4,
          scaleId: SCALE_2,
        })
      )
    }

    if (questionWithSubquestionsDualScale2Theme.includes(questionThemeName)) {
      subQuestions.push(
        getQuestionExample({
          sortOrder: 3,
          language,
          parentQid: questionId,
          type: QuestionTypeInfo.LONG_TEXT.type,
          questionThemeName: QuestionTypeInfo.LONG_TEXT.theme,
          gid: questionGroupToAddQuestion.gid,
          sid: questionGroupToAddQuestion.sid,
          scaleId: SCALE_2,
        }),
        getQuestionExample({
          sortOrder: 4,
          language,
          parentQid: questionId,
          type: QuestionTypeInfo.LONG_TEXT.type,
          questionThemeName: QuestionTypeInfo.LONG_TEXT.theme,
          gid: questionGroupToAddQuestion.gid,
          sid: questionGroupToAddQuestion.sid,
          scaleId: SCALE_2,
        })
      )
    }

    const newQuestion = getQuestionExample({
      qid: questionId,
      gid: questionGroupToAddQuestion.gid,
      sid: questionGroupToAddQuestion.sid,
      type: questionTypeInfo.type,
      questionThemeName: questionThemeName,
      answers: answers,
      subquestions: subQuestions,
      sortOrder: questionGroupToAddQuestion.questions.length + 1,
    })

    handleAddQuestion(newQuestion)
    setIsAddingQuestionOrGroup(false)
  }

  return (
    <div
      data-testid="topbar-question-inserter"
      className="mt-4 position-fixed question-type-selector-container"
    >
      <QuestionTypeSelector
        disableAddingQuestions={!questionGroupToAddQuestion}
        callBack={addNewQuestion}
      />
    </div>
  )
}
