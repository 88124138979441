import { FormCheck } from 'react-bootstrap'

import { FivePointChoiceAnswerType } from './FivePointChoiceAnswertype'
import './FivePointChoiceQuestion.scss'
import { ContentEditor } from 'components/UIComponents'
import { NO_ANSWER_LABEL } from '../../../helpers'

export const FivePointChoiceQuestion = ({ question, surveySettings }) => {
  const showNoAnswer = !question.mandatory && surveySettings.showNoAnswer
  return (
    <div className="d-flex gap-4 mb-3 five-point-choice-question question-body-content">
      {Object.values(FivePointChoiceAnswerType).map((point) => {
        if (
          point === FivePointChoiceAnswerType.EMPTY ||
          point === FivePointChoiceAnswerType.NO_ANSWER ||
          typeof point !== 'number'
        ) {
          return ''
        }

        return (
          <FormCheck
            key={`${question.qid}-five-point-${point}`}
            type="radio"
            label={
              <ContentEditor className="choice" value={point} disabled={true} />
            }
            name={`${question.qid}-five-point`}
            data-testid="five-point-choice-question-answer"
            value={point}
            className="choice"
          />
        )
      })}
      {showNoAnswer && (
        <FormCheck
          key={`${question.qid}-five-point-empty`}
          type="radio"
          label={NO_ANSWER_LABEL}
          name={`${question.qid}-five-point`}
          data-testid="five-point-choice-question-answer"
          value={FivePointChoiceAnswerType.NO_ANSWER}
          className="choice"
        />
      )}
    </div>
  )
}
