import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'

import { useAppState, useErrors, useFocused } from 'hooks'
import {
  DISABLED_QUESTION_TYPES,
  Entities,
  getAttributeValue,
  isTrue,
  ScrollToElement,
  STATES,
  TOOLTIP_MESSAGES,
} from 'helpers'
import { ArrowDownIcon, ArrowUpIcon } from 'components/icons'

import { QuestionHeader } from './QuestionHeader'
import { QuestionBody } from './QuestionBody'
import { QuestionFooter } from './QuestionFooter'
import { QuestionTypeInfo } from '../../QuestionTypes'
import { TooltipContainer } from '../../TooltipContainer/TooltipContainer'

export const Question = ({
  language,
  question: { attributes = {} },
  question,
  handleRemove,
  handleDuplicate,
  update,
  questionNumber,
  groupIndex,
  questionIndex,
  lastQuestionIndex,
  questionGroupIsOpen,
  handleSwapQuestionPosition,
  isTestMode = false,
  surveySettings,
}) => {
  const questionRef = useRef(null)
  const { focused = {}, setFocused } = useFocused()
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const [isHovered, setIsHovered] = useState(false)
  const { getError } = useErrors()
  const filteredThemeName = Object.values(QuestionTypeInfo).map((q) => q.theme)

  const isQuestionDisabled = useMemo(() => {
    return (
      (DISABLED_QUESTION_TYPES.includes(question.questionThemeName) ||
        !filteredThemeName.includes(question.questionThemeName)) &&
      process.env.STORYBOOK_DEV !== 'true'
    )
  }, [question.questionThemeName])

  const [, setHasErrors] = useState(false)

  const handleFocusQuestion = () => {
    const questionIsNotFocused = question.qid !== focused.qid
    if (questionIsNotFocused) {
      setFocused(question, groupIndex, questionIndex)
    }
  }

  const handleOnErrors = (errors) => setHasErrors(errors)

  useEffect(() => {
    const questionIsFocused =
      focused.type === question.type && question.title === focused.title

    if (questionIsFocused && focused.qid !== question.qid) {
      setFocused(question, groupIndex, questionIndex, false)
    }

    if (!questionIsFocused) {
      return
    }

    if (questionGroupIsOpen) {
      ScrollToElement(questionRef.current)
    }
  }, [focused.qid, question.qid, question.sortOrder])

  const handleUpdate = (change) => {
    update({
      ...question,
      ...change,
    })
  }

  if (!question?.qid) {
    return <></>
  }

  return (
    <TooltipContainer
      tip={TOOLTIP_MESSAGES.NO_PERMISSION}
      showTip={!hasSurveyUpdatePermission}
      placement="left"
    >
      <div
        data-error={getError(question.qid, Entities.question)}
        onClick={handleFocusQuestion}
        id={`${question.qid}-question`}
        data-testid={`question`}
        className={classNames(
          'question d-flex position-relative',
          getAttributeValue(attributes.cssclass),
          {
            'focus-element': focused.qid === question.qid,
            'hover-element': focused.qid !== question.qid,
            'opacity-25': isTrue(getAttributeValue(attributes.hide_question)),
            'cursor-not-allowed': !hasSurveyUpdatePermission,
          }
        )}
        ref={questionRef}
      >
        {!isTestMode && focused.qid === question.qid && (
          <div className="position-absolute question-scroll">
            <div>
              <Button
                variant="secondary"
                onClick={() => handleSwapQuestionPosition(-1)}
                size="sm"
                disabled={questionIndex === 0}
                className="question-scroll-button"
                data-testid="question-arrow-up-button"
              >
                <ArrowUpIcon className="text-white fill-current" />
              </Button>
            </div>
            <div className="mt-1">
              <Button
                onClick={() => handleSwapQuestionPosition(+1)}
                variant="secondary"
                size="sm"
                disabled={questionIndex === lastQuestionIndex}
                className="question-scroll-button"
                data-testid="question-arrow-down-button"
              >
                <ArrowDownIcon className="text-white fill-current" />
              </Button>
            </div>
          </div>
        )}
        <div
          className={classNames('w-100 d-flex flex-column', {
            'w-50': attributes?.image?.preview,
          })}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          data-testid="question-container"
        >
          <QuestionHeader
            handleUpdate={handleUpdate}
            language={language}
            question={question}
            questionNumber={questionNumber}
            onError={(errors) => handleOnErrors(errors)}
            isFocused={focused.qid === question.qid}
          />
          <QuestionBody
            language={language}
            question={question}
            handleUpdate={handleUpdate}
            questionNumber={questionNumber}
            isFocused={focused.qid === question.qid}
            isHovered={isHovered}
            isQuestionDisabled={isQuestionDisabled}
            surveySettings={surveySettings}
          />
          {hasSurveyUpdatePermission && (
            <div>
              <QuestionFooter
                question={question}
                isFocused={focused.qid === question.qid}
                handleUpdate={handleUpdate}
                handleRemove={handleRemove}
                handleDuplicate={handleDuplicate}
              />
            </div>
          )}
        </div>
      </div>
    </TooltipContainer>
  )
}
