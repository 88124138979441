import { FormCheck } from 'react-bootstrap'
import WomenLineIcon from 'remixicon-react/WomenLineIcon'
import MenLineIcon from 'remixicon-react/MenLineIcon'
import CheckboxBlankCircleLineIcon from 'remixicon-react/CheckboxBlankCircleLineIcon'

import {
  DISPLAY_BUTTON_TYPE_OPTIONS,
  getAttributeValue,
  isTrue,
  NO_ANSWER_LABEL,
} from 'helpers'
import { Button } from 'components/UIComponents'

export const GenderQuestion = ({ question, surveySettings }) => {
  const options = [
    { icon: WomenLineIcon, name: 'Female', value: 'female' },
    { icon: MenLineIcon, name: 'Male', value: 'male' },
  ]

  const displayType = getAttributeValue(question.attributes.display_type)
  const showNoAnswer =
    !isTrue(question.mandatory) && surveySettings.showNoAnswer

  if (showNoAnswer) {
    options.push({
      icon: CheckboxBlankCircleLineIcon,
      name: NO_ANSWER_LABEL,
      value: 'no answer',
    })
  }

  return (
    <div
      className="gender-question d-flex align-items-center gap-3"
      style={{ maxWidth: 400 }}
      data-testid="gender-question"
    >
      {displayType === DISPLAY_BUTTON_TYPE_OPTIONS.RADIO.value && (
        <div className="d-flex align-items-center gap-3">
          <FormCheck
            value={'female'}
            type={'radio'}
            label={'Female'}
            name={`${question.qid}-gender-question-radio-list`}
            data-testid="gender-question-option"
          />
          <FormCheck
            value={'male'}
            type={'radio'}
            label={'Male'}
            name={`${question.qid}-gender-question-radio-list`}
            data-testid="gender-question-option"
          />
          {!isTrue(question.mandatory) && (
            <FormCheck
              value={'no answer'}
              type={'radio'}
              label={NO_ANSWER_LABEL}
              name={`${question.qid}-gender-question-radio-list`}
              data-testid="gender-question-option"
            />
          )}
        </div>
      )}
      <div className="gender-buttons d-flex align-items-center gap-3">
        {displayType !== DISPLAY_BUTTON_TYPE_OPTIONS.RADIO.value && (
          <>
            {options.map((option, idx) => (
              <Button
                className={'button-question'}
                key={`gender-question-button-${idx}`}
                id={`${question.qid}-gender-question`}
                Icon={option.icon}
                name={option.name}
                text={option.name}
                iconSize={32}
                variant="outline-success"
                testId="gender-question-option"
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}
