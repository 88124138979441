import { useEffect, useState, useRef } from 'react'
import { RemoveHTMLTagsInString, STATES, TOOLTIP_MESSAGES } from 'helpers'

import { RichEditor } from './RichEditor'
import { ContentEditable } from './ContentEditable'
import { useAppState } from '../../../hooks'
import { TooltipContainer } from '../../TooltipContainer/TooltipContainer'

export const ContentEditor = ({
  value = '',
  placeholder = '',
  className = '',
  update = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClick = () => {},
  setErrors = () => {},
  onKeyDown = () => {},
  style = {},
  contentEditableRef,
  testValidation,
  id,
  language,
  focus = false,
  disabled = false,
  replaceVariables = false,
  useRichTextEditor = false,
  contentRef,
  noPermissionDisabled = false,
  noAccessDisabled = false,
  showToolTip = true,
  toolTipPlacement = 'top',
  testId = 'content-editor',
}) => {
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const editorRef = useRef(null)

  const editorDisabled =
    disabled ||
    (!hasSurveyUpdatePermission && noPermissionDisabled) ||
    noAccessDisabled
  // the purpose of using a state variable is to be able to replace the variables without changing the state.
  // changing the state will make us lose the original value.
  const [isFocused, setIsFocused] = useState(false)
  const [showRichTextEditor, setShowRichTextEditor] = useState(false)
  const toolTip =
    (!hasSurveyUpdatePermission && noPermissionDisabled) || noAccessDisabled
      ? TOOLTIP_MESSAGES.NO_PERMISSION
      : ''

  const handleOnClick = () => {
    onClick()
  }

  const handleFocus = (e) => {
    onFocus(e)
    setIsFocused(true)
  }

  const handleBlur = (e) => {
    onBlur(e)
    setIsFocused(false)

    const errors = getValidationErrors(RemoveHTMLTagsInString(value))

    setErrors(errors)
  }

  const getValidationErrors = (questionTitle) => {
    if (!testValidation) {
      return []
    }

    const validationErrors = testValidation(questionTitle)

    return validationErrors?.error
  }

  const handleOnChange = (changeValue) => {
    if (setErrors && !focus) {
      const errors = getValidationErrors(RemoveHTMLTagsInString(changeValue))
      setErrors(errors)
    }

    if (changeValue == value) {
      return
    }

    update(changeValue)
  }

  useEffect(() => {
    if (focus && contentEditableRef?.current?.focus) {
      setTimeout(() => {
        contentEditableRef?.current?.focus()
      }, 0)
    }

    if (!setErrors || focus) {
      return
    }

    const errors = getValidationErrors(RemoveHTMLTagsInString(value))
    setErrors(errors)
  }, [])

  useEffect(() => {
    if (!showRichTextEditor) {
      return
    }

    setShowRichTextEditor(false)
    setTimeout(() => {
      setShowRichTextEditor(true)
    }, 0)
  }, [language])

  const closeEditorOnOutsideClick = useRef((event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      setIsFocused(false)
      setShowRichTextEditor(false)
    }
  })

  useEffect(() => {
    const handleOutsideClick = closeEditorOnOutsideClick.current
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  return (
    <div
      ref={editorRef}
      style={style}
      onClick={handleOnClick}
      onMouseEnter={() => {
        setShowRichTextEditor(true)
      }}
      onMouseLeave={() => {
        if (!isFocused) setShowRichTextEditor(false)
      }}
      className={`content-editor ${className}`}
      id={id}
    >
      <TooltipContainer
        tip={toolTip}
        showTip={editorDisabled && showToolTip}
        placement={toolTipPlacement}
      >
        {useRichTextEditor && showRichTextEditor ? (
          <RichEditor
            disabled={editorDisabled}
            focus={focus}
            onBlur={handleBlur}
            onFocus={handleFocus}
            handleOnChange={handleOnChange}
            placeholder={placeholder}
            showToolbar={isFocused}
            value={value}
            replaceVariables={replaceVariables}
            testId={testId}
            contentRef={contentRef}
          />
        ) : (
          <ContentEditable
            testId={testId}
            contentEditableRef={contentEditableRef}
            disabled={editorDisabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            handleOnChange={handleOnChange}
            placeholder={placeholder}
            value={value?.toString()}
            replaceVariables={replaceVariables}
            focus={focus}
            onKeyDown={onKeyDown}
            contentRef={contentRef}
          />
        )}
      </TooltipContainer>
    </div>
  )
}
