import { useCallback } from 'react'
import { debounce } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { queryClient } from 'queryClient'
import { STATES } from 'helpers'

import { useAppState, useQueryRetry, useSurveyService } from './'

const PATCH_DEBOUNCE_TIME = 1000

export const useSurvey = (id) => {
  const { surveyService } = useSurveyService()
  const { refetchInterval, handleRetry } = useQueryRetry({})
  const [, setSurveyRefreshRequired] = useAppState(
    STATES.SURVEY_REFRESH_REQUIRED
  )
  const [surveyHash, setSurveyHash] = useAppState(STATES.SURVEY_HASH, {
    updateHash: 0,
    refetchHash: 0,
  })

  let { data } = useQuery({
    queryKey: [STATES.SURVEY],
    queryFn: async ({ signal }) => {
      return fetchSurvey(id, signal)
    },
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    refetchInterval: refetchInterval,
    retry: handleRetry,
    meta: {
      persist: true,
    },
  })

  const fetchSurvey = async (id, signal) => {
    const currentSurveyId = data?.survey?.sid?.toString()
    const surveyRefetch = id?.toString() === currentSurveyId
    const isDemoMode = process.env.REACT_APP_DEMO_MODE === 'true'

    if ((!id && !isDemoMode) || (isDemoMode && currentSurveyId)) {
      return { survey: data.survey || {} }
    }

    // If the survey is not refetching then set the survey to be empty to trigger the loading state.
    if (!surveyRefetch) {
      setSurvey({})
    }

    if (
      process.env.REACT_APP_DEMO_MODE === 'true' ||
      process.env.STORYBOOK_DEV === 'true'
    ) {
      const data = await surveyService.getSurveyDetailDemo(id)
      setSurvey(data.survey)

      return data
    } else {
      const newData = await surveyService
        .getSurveyDetail(id, signal)
        .catch((error) => {
          throw new Error(error)
        })

      const operationsBuffer = queryClient.getQueryData([STATES.BUFFER])
      const isPatchSurveyRunning = queryClient.getQueryData([
        'appState',
        STATES.IS_PATCH_SURVEY_RUNNING,
      ])
      // Return currentData if the buffer is not empty.
      // We are also checking if the data is defined because when the app first loads the data or the survey is not defined yet.
      if ((operationsBuffer?.length || isPatchSurveyRunning) && data) {
        // we should schdule a refetch to update the survey data.
        setSurveyRefreshRequired(true)
        queryClient.cancelQueries({ queryKey: [STATES.SURVEY] })
        return data
      }

      setSurvey(newData.survey)

      // Survey hash is used to keep track of the survey data.
      setSurveyHash({ ...surveyHash, refetchHash: Math.random() })
      return newData
    }
  }

  let { data: surveyList } = useQuery({
    queryKey: ['surveyList'],
    queryFn: async () => {
      if (process.env.REACT_APP_DEMO_MODE !== 'true') {
        return surveyService.getSurveyList()
      }

      return []
    },
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    refetchInterval,
    retry: handleRetry,
  })

  const clearSurvey = () => {
    if (
      process.env.REACT_APP_DEMO_MODE === 'true' ||
      process.env.STORYBOOK_DEV === 'true'
    ) {
      return
    }

    setSurvey({})
  }

  const setSurvey = (surveyData = {}) => {
    queryClient.setQueryData([STATES.SURVEY], {
      survey: {
        ...surveyData,
      },
    })
  }

  const updateSurvey = (updateData) => {
    setSurveyHash({ ...surveyHash, updateHash: Math.random() })

    queryClient.setQueryData([STATES.SURVEY], {
      survey: {
        ...data.survey,
        ...updateData,
      },
    })
  }

  const surveyPatch = useCallback(
    debounce(
      async (
        operations,
        beforeCallback = () => {},
        thenCallback = () => {},
        finallyCallback = () => {},
        catchCallBack = () => {}
      ) => {
        if (!operations.length) {
          return
        }

        if (
          // process.env.REACT_APP_DEMO_MODE === 'true' ||
          // Storybook dev context
          process.env.STORYBOOK_DEV
        ) {
          return
        }

        beforeCallback()
        return surveyService
          .patchSurvey(operations)
          .then(thenCallback)
          .finally(finallyCallback)
          .catch(catchCallBack)
      },
      PATCH_DEBOUNCE_TIME
    ),
    [surveyService.surveyId, surveyService.auth?.restHeaders?.Authorization]
  )

  return {
    survey: data?.survey || {},
    surveyList: surveyList?.surveys || [],
    update: updateSurvey,
    language: data?.survey?.language,
    surveyPatch,
    clearSurvey,
    fetchSurvey,
    surveyMenus: data?.survey?.surveyMenus,
    surveyHash,
  }
}
