import classNames from 'classnames'

import { ContentEditor } from 'components'
import { CloseCircleFillIcon, DragIcon } from 'components/icons'
import { RemoveHTMLTagsInString, STATES } from 'helpers'
import { useState } from 'react'
import { useAppState } from '../../../../hooks'

export const ArrayHorizontalTitle = ({
  isFocused,
  removeItem,
  dragIconSize,
  provided = {},
  highestHeight,
  index,
  title,
  handleUpdateL10ns,
  placeHolder = 'Answer option',
  itemsKey,
  isNoAnswer = false,
}) => {
  const [answerValue, setAnswerValue] = useState(title)
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)

  const handleAnswerUpdate = (value) => {
    handleUpdateL10ns(value, index)
    setAnswerValue(RemoveHTMLTagsInString(value) ? value : '')
  }

  return (
    <div
      className={classNames(
        'd-flex array-question-item position-relative remove-option-button-parent'
      )}
    >
      <div
        {...provided.dragHandleProps}
        className={classNames(
          {
            'disabled opacity-0': !provided.dragHandleProps,
          },
          'd-flex align-items-center'
        )}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {isFocused && <DragIcon className="text-secondary fill-current" />}
      </div>
      {!(isSurveyActive && itemsKey === 'subquestions') && (
        <div
          className="cursor-pointer remove-option-button remove-item-button"
          onClick={() => removeItem(index)}
          style={{
            position: 'absolute',
            top: '-10px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          data-testid="remove-horizontal-option-button"
        >
          <CloseCircleFillIcon
            className={classNames('text-danger fill-current', {
              'd-none disabled': !isFocused || isNoAnswer,
            })}
          />
        </div>
      )}
      <div
        style={{
          minHeight: highestHeight,
          paddingLeft: dragIconSize,
          paddingRight: dragIconSize,
        }}
      >
        <ContentEditor
          className={classNames(
            'text-start choice array-answer-content-editor'
          )}
          placeholder={`${placeHolder} ${index + 1}`}
          value={answerValue}
          update={(value) => handleAnswerUpdate(value)}
          disabled={isNoAnswer}
        />
      </div>
    </div>
  )
}
