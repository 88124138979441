export const Entities = {
  questionGroup: 'questionGroup',
  questionGroupL10n: 'questionGroupL10n',
  question: 'question',
  questionL10n: 'questionL10n',
  questionAttribute: 'questionAttribute',
  subquestion: 'subquestion',
  answer: 'answer',
  languageSetting: 'languageSetting',
  survey: 'survey',
  surveyStatus: 'surveyStatus',
  questionGroupReorder: 'questionGroupReorder',
}
