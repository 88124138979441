import React, { useCallback, useMemo, useState } from 'react'
import { FormCheck, Image } from 'react-bootstrap'

import NoImageFound from 'assets/images/no-image-found.jpg'
import { DropZone } from 'components/UIComponents'
import { useFileService } from 'hooks'
import { isTempId, IMAGE_PREVIEW_WIDTH } from 'helpers'
export const ImageChoice = ({
  id,
  idPrefix,
  index,
  inputType = 'checkbox',
  isFocused,
  update,
  value,
  isNoAnswer,
}) => {
  const { fileService } = useFileService()
  const [previewUrl, setPreviewUrl] = useState(
    value ? process.env.REACT_APP_SITE_URL + value : null
  )

  const onChange = useCallback(
    (filePath) => {
      update(encodeURI(filePath))
    },
    [update]
  )
  const onChangePreview = useCallback(
    (previewUrl) => {
      setPreviewUrl(previewUrl)
    },
    [setPreviewUrl]
  )

  const initLoading = useMemo(() => isTempId(id), [id])

  const regexContainsHtml = /(<([^>]+)>)/i
  const isValidImg = useMemo(() => !regexContainsHtml.test(value), [value])

  const idSuffix = idPrefix + id + '-i' + index

  return (
    <div className="pe-4">
      <div className="mb-2 d-flex gap-2">
        {(!isFocused || isNoAnswer) && (
          <>
            <FormCheck
              type={inputType}
              className="pointer-events-none"
              name={'image-choice-' + idSuffix}
              data-testid={'image-choice-' + idSuffix}
              label={isNoAnswer ? 'No answer' : ''}
            />
            {!isNoAnswer && (
              <div className="border border-3 border-secondary rounded">
                <Image
                  src={previewUrl && isValidImg ? previewUrl : NoImageFound}
                  alt="Image Select List"
                  width={'100%'}
                  style={{
                    maxWidth: IMAGE_PREVIEW_WIDTH,
                    backgroundSize: 'cover',
                  }}
                />
              </div>
            )}
          </>
        )}
        {isFocused && initLoading && !isNoAnswer && (
          <div style={{ width: 44, height: 44 }} className="loader ms-4"></div>
        )}
        {isFocused && !initLoading && !isNoAnswer && (
          <DropZone
            dataTestId={'dropzone-' + idSuffix}
            previewUrlInit={previewUrl}
            fileService={fileService}
            previewMaxWidth={IMAGE_PREVIEW_WIDTH}
            onChange={onChange}
            onChangePreview={onChangePreview}
            isValidImg={isValidImg}
          />
        )}
      </div>
      {!isValidImg && !isNoAnswer && (
        <div>
          <a
            href="https://www.limesurvey.org/manual/Image_Question_HTML_Image_Value"
            target="_blank"
            rel="noreferrer"
          >
            This image is not valid. Click here for more info.
          </a>
        </div>
      )}
    </div>
  )
}
