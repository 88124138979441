// placeholder.js
// Utility functions for managing and formatting question placeholders
// within the context of a survey, including functions to fetch placeholders
// for questions, check if a placeholder is in use, and format placeholder text.

import { RemoveHTMLTagsInString } from './RemoveHTMLTagsInString'

export const getQuestionPlaceholders = (language, question) => {
  const placeholderList = []

  const extractPlaceholders = (text) => {
    const matches = text.match(/{([^}]+)}/g)

    if (matches) {
      return matches.map((match) =>
        match
          .replace(/TOKEN:/g, '')
          .replace(/\.shown/g, '')
          .slice(1, -1)
      )
    }
    return []
  }

  if (question?.l10ns?.[language]?.question) {
    placeholderList.push(
      ...extractPlaceholders(question.l10ns[language].question)
    )
  }

  return placeholderList
}

export const isPlaceholderInUse = (language, question, placeholderKey) => {
  if (!question) return false

  for (const placeholder of getQuestionPlaceholders(language, question)) {
    if (placeholderKey === placeholder) {
      return true
    }
  }
  return false
}

export const getFormattedPlaceholderText = (
  language,
  question,
  subQuestion = null
) => {
  const getText = (item, fallback) =>
    RemoveHTMLTagsInString(item.l10ns?.[language]?.question || fallback)

  const questionText = getText(question, question.title)

  if (subQuestion) {
    const subQuestionText = getText(
      subQuestion,
      `${questionText}: ${subQuestion.title}`
    )
    return `${question.title}: [${subQuestionText}] ${questionText}`
  }

  return `${question.title}: ${questionText}`
}
