import Joi from 'joi'

import { Operations, Entities } from 'helpers'
import {
  questionCreateJoi,
  questionDeleteJoi,
  questionL10nUpdateJoi,
  questionUpdateJoi,
} from './operationsScheme/question'
import { questionGroupUpdateJoi } from './operationsScheme/questionGroup/questionGroupUpdateJoi'
import { answerUpdateJoi } from './operationsScheme/answer/answerUpdateJoi'
import { subquestionUpdateJoi } from './operationsScheme/subQuestion/subquestionUpdateJoi'
import { questionGroupL10nUpdateJoi } from './operationsScheme/questionGroup/questionGroupL10nUpdateJoi'
import { surveyUpdateJoi } from './operationsScheme/survey/surveyUpdateJoi'
import { surveyStatusUpdateJoi } from './operationsScheme/survey/surveyStatusUpdateJoi'
import { questionGroupCreateJoi } from './operationsScheme/questionGroup/questionGroupCreateJoi'
import { answerCreateJoi } from './operationsScheme/answer/answerCreateJoi'
import { subquestionCreateJoi } from './operationsScheme/subQuestion/subquestionCreateJoi'
import { questionGroupDeleteJoi } from './operationsScheme/questionGroup/questionGroupDeleteJoi'
import { answerDeleteJoi } from './operationsScheme/answer/answerDeleteJoi'
import { subquestionDeleteJoi } from './operationsScheme/subQuestion/subquestionDeleteJoi'
import { languageSettingUpdateJoi } from './operationsScheme/survey/languageSettingUpdateJoi'

export const getOperationScheme = (operation, entity) => {
  let scheme

  if (operation === Operations.update) {
    if (entity === Entities.question) {
      scheme = questionUpdateJoi
    } else if (entity === Entities.questionGroup) {
      scheme = questionGroupUpdateJoi
    } else if (entity === Entities.answer) {
      scheme = answerUpdateJoi
    } else if (entity === Entities.subquestion) {
      scheme = subquestionUpdateJoi
    } else if (entity === Entities.questionL10n) {
      scheme = questionL10nUpdateJoi
    } else if (entity === Entities.questionGroupL10n) {
      scheme = questionGroupL10nUpdateJoi
    } else if (entity === Entities.survey) {
      scheme = surveyUpdateJoi
    } else if (entity === Entities.surveyStatus) {
      scheme = surveyStatusUpdateJoi
    } else if (entity === Entities.languageSetting) {
      scheme = languageSettingUpdateJoi
    }
  } else if (operation === Operations.create) {
    if (entity === Entities.question) {
      scheme = questionCreateJoi
    } else if (entity === Entities.questionGroup) {
      scheme = questionGroupCreateJoi
    } else if (entity === Entities.answer) {
      scheme = answerCreateJoi
    } else if (entity === Entities.subquestion) {
      scheme = subquestionCreateJoi
    }
  } else if (operation === Operations.delete) {
    if (entity === Entities.question) {
      scheme = questionDeleteJoi
    } else if (entity === Entities.questionGroup) {
      scheme = questionGroupDeleteJoi
    } else if (entity === Entities.answer) {
      scheme = answerDeleteJoi
    } else if (entity === Entities.subquestion) {
      scheme = subquestionDeleteJoi
    }
  } else {
    throw new Error(`Unsupported operation: ${operation}`)
  }

  scheme = scheme?.keys({ error: Joi.any().optional() })

  return scheme
}
