import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { useBuffer, useErrors } from 'hooks'
import {
  isTrue,
  L10ns,
  RemoveHTMLTagsInString,
  createBufferOperation,
  Entities,
} from 'helpers'
import { ContentEditor } from 'components/UIComponents'
import { ArrowRightIcon } from 'components/icons'

import { TestValidation } from './QuestionSchema'

export const QuestionHeader = ({
  handleUpdate,
  language,
  question,
  questionNumber,
  disabled = false,
  onError = () => {},
}) => {
  const { addToBuffer } = useBuffer()
  const [errors, setErrors] = useState('')
  const { getError } = useErrors()

  const indexRef = useRef(null)
  const titleRef = useRef(null)

  const wrapPlaceholdersWithBadge = (questionTitle) => {
    const withBadges = questionTitle.replace(
      /<span class="badge bg-primary.*?>.*?<\/span>/g,
      (match) => match
    )
    return withBadges.replace(/{([^}]+)}/g, (match, p1, offset) => {
      const prefix = offset > 0 ? ' ' : ''
      return `${prefix}<span class="badge bg-primary d-inline">${match}</span>`
    })
  }

  const updateTitle = (updated) => {
    if (!question.l10ns[language]) {
      question.l10ns[language] = {}
    }

    const processedTitle = wrapPlaceholdersWithBadge(updated.question)
    question.l10ns[language].question = processedTitle

    const operation = createBufferOperation(question.qid)
      .questionL10n()
      .update({
        [language]: {
          question: processedTitle,
        },
      })

    handleUpdate({ question })
    addToBuffer(operation)
  }

  const updateDescription = (updated) => {
    if (!question.l10ns[language]) {
      question.l10ns[language] = {}
    }

    question.l10ns[language].help = updated.help
    const operation = createBufferOperation(question.qid)
      .questionL10n()
      .update({
        [language]: {
          help: updated.help,
        },
      })

    handleUpdate({ question })
    addToBuffer(operation)
  }

  useEffect(() => {
    onError(errors)
  }, [errors, onError])

  const questionTitle = L10ns({
    prop: 'question',
    language,
    l10ns: question.l10ns,
  })

  const questionDescription = L10ns({
    prop: 'help',
    language,
    l10ns: question.l10ns,
  })

  const titleWithoutHtml = RemoveHTMLTagsInString(questionTitle)
  const descriptionWithoutHtml = RemoveHTMLTagsInString(questionDescription)

  return (
    <div
      data-testid="question-header"
      className="question-header d-flex flex-row"
    >
      <div className=" d-flex flex-column">
        <div className="question-title d-flex align-items-center py-3">
          <div
            className="d-flex align-items-center gap-1 question-number"
            ref={indexRef}
          >
            <div data-testid="question-number">{questionNumber}</div>
            <ArrowRightIcon className="text-primary fill-current" />
          </div>
          <h2 data-error={getError(question.qid, Entities.questionL10n)}>
            <ContentEditor
              testId="question-content-editor"
              className={classNames(
                question.attributes?.cssclass?.value,
                {
                  'error-focus': errors,
                },
                'question-title'
              )}
              innerRef={titleRef}
              placeholder={'Your question here'}
              useRichTextEditor={!process.env.STORYBOOK_DEV}
              update={(question) => updateTitle({ question })}
              value={questionTitle}
              testValidation={TestValidation}
              setErrors={setErrors}
              style={{
                width: titleWithoutHtml ? 'fit-content' : '210px',
              }}
              language={language}
              replaceVariables={true}
              disabled={disabled}
              noPermissionDisabled={true}
              showToolTip={false}
            />
          </h2>
          {isTrue(question.mandatory) ? (
            <span className="ms-2" style={{ fontSize: '24px' }}>
              *
            </span>
          ) : (
            ''
          )}
        </div>
        <div
          className={classNames(
            'question-description d-flex align-items-center'
          )}
        >
          <ContentEditor
            testId={'question-help-description-content-editor'}
            placeholder="Optional help description"
            update={(help) => updateDescription({ help })}
            value={questionDescription}
            style={{
              width: descriptionWithoutHtml ? 'fit-content' : '250px',
              lineHeight: '16px',
            }}
            disabled={disabled}
            noPermissionDisabled={true}
            showToolTip={false}
          />
        </div>
      </div>
    </div>
  )
}
