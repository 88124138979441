import classNames from 'classnames'

import {
  FivePointChoiceQuestion,
  TextQuestion,
  RatingQuestion,
  QuestionTypeInfo,
  MultipleChoice,
  SingleChoice,
  FileUpload,
  RankingQuestion,
  RankingAdvancedQuestion,
  Equation,
  DateTime,
  GenderQuestion,
  YesNoQuestion,
  ArrayQuestion,
} from '../../QuestionTypes'
import { useAppState } from '../../../hooks'
import { STATES } from '../../../helpers'

const questionComponents = {
  [QuestionTypeInfo.SINGLE_CHOICE_LIST_RADIO.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_LIST_RADIO_WITH_COMMENT.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.theme]: SingleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_IMAGE_SELECT.theme]: SingleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.theme]: MultipleChoice,
  [QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.theme]: MultipleChoice,
  [QuestionTypeInfo.SINGLE_CHOICE_FIVE_POINT_CHOICE.theme]:
    FivePointChoiceQuestion,
  [QuestionTypeInfo.SHORT_TEXT.theme]: TextQuestion,
  [QuestionTypeInfo.BROWSER_DETECTION.theme]: TextQuestion,
  [QuestionTypeInfo.LONG_TEXT.theme]: TextQuestion,
  [QuestionTypeInfo.ARRAY.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_NUMBERS.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_TEXT.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_COLUMN.theme]: ArrayQuestion,
  [QuestionTypeInfo.ARRAY_DUAL_SCALE.theme]: ArrayQuestion,
  [QuestionTypeInfo.RATING.theme]: RatingQuestion,
  [QuestionTypeInfo.FILE_UPLOAD.theme]: FileUpload,
  [QuestionTypeInfo.RANKING.theme]: RankingQuestion,
  [QuestionTypeInfo.RANKING_ADVANCED.theme]: RankingAdvancedQuestion,
  [QuestionTypeInfo.EQUATION.theme]: Equation,
  [QuestionTypeInfo.DATE_TIME.theme]: DateTime,
  [QuestionTypeInfo.GENDER.theme]: GenderQuestion,
  [QuestionTypeInfo.YES_NO.theme]: YesNoQuestion,
}

export const QuestionBody = ({
  question,
  handleUpdate,
  language,
  isFocused,
  isHovered,
  isQuestionDisabled,
  surveySettings,
}) => {
  const QuestionComponent = questionComponents[question.questionThemeName]
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const isFocusedWithPermission = isFocused && hasSurveyUpdatePermission

  if (!QuestionComponent) {
    return <></>
  }

  return (
    <div
      className={classNames('question-body', {
        'disabled opacity-50': isQuestionDisabled,
      })}
    >
      <QuestionComponent
        handleUpdate={handleUpdate}
        question={question}
        language={language}
        isFocused={isFocusedWithPermission}
        isHovered={isHovered}
        surveySettings={surveySettings}
      />
    </div>
  )
}
