import { FormCheck } from 'react-bootstrap'
import CheckboxCircleLineIcon from 'remixicon-react/CheckboxCircleLineIcon'
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon'
import CheckboxBlankCircleLineIcon from 'remixicon-react/CheckboxBlankCircleLineIcon'

import {
  DISPLAY_BUTTON_TYPE_OPTIONS,
  getAttributeValue,
  isTrue,
  NO_ANSWER_LABEL,
} from 'helpers'
import { Button } from 'components/UIComponents'

export const YesNoQuestion = ({ question, surveySettings }) => {
  const options = [
    { icon: CheckboxCircleLineIcon, name: 'Yes', value: 'yes' },
    { icon: CloseCircleLineIcon, name: 'No', value: 'no' },
  ]

  const displayType = getAttributeValue(question.attributes.display_type)
  const showNoAnswer =
    !isTrue(question.mandatory) && surveySettings.showNoAnswer
  if (showNoAnswer) {
    options.push({
      icon: CheckboxBlankCircleLineIcon,
      name: NO_ANSWER_LABEL,
      value: '',
    })
  }

  return (
    <div
      className="yes-no-question d-flex align-items-center gap-3"
      style={{ maxWidth: 400 }}
      data-testid="yes-no-question"
    >
      {displayType === DISPLAY_BUTTON_TYPE_OPTIONS.RADIO.value && (
        <>
          <FormCheck
            value={'yes'}
            type={'radio'}
            label={'Yes'}
            name={`${question.qid}-yes-no-question-radio-list`}
            data-testid="yes-no-question-answer"
          />
          <FormCheck
            value={'no'}
            type={'radio'}
            label={'No'}
            name={`${question.qid}-yes-no-question-radio-list`}
            data-testid="yes-no-question-answer"
          />
          {!isTrue(question.mandatory) && (
            <FormCheck
              value={'no answer'}
              type={'radio'}
              label={NO_ANSWER_LABEL}
              name={`${question.qid}-yes-no-question-radio-list`}
              data-testid="yes-no-question-answer"
            />
          )}
        </>
      )}
      {displayType !== DISPLAY_BUTTON_TYPE_OPTIONS.RADIO.value && (
        <>
          {options.map((option, idx) => (
            <Button
              className={'button-question yes-no-question-button'}
              key={`yes-no-question-button-${idx}`}
              id={`${question.qid}-yes-no-question`}
              Icon={option.icon}
              name={option.name}
              text={option.name}
              testId="yes-no-question-answer"
              value={option.value}
              iconSize={32}
              variant="outline-success"
            />
          ))}
        </>
      )}
    </div>
  )
}
