import { ToggleButtons, Input, Select } from 'components/UIComponents'
import {
  Entities,
  getSettingValueFromSurvey,
  ignoreUpdate,
  NO_ANSWER_LABEL,
  STATES,
} from 'helpers'

import { CheckboxRadio } from '../../UIComponents/CheckboxRadio/CheckboxRadio'

export const PresentationSettingsBlocks = {
  DISPLAY: {
    title: 'Display',
    settings: {
      SHOW_PROGRESS: {
        keyPath: 'showProgress',
        props: {
          id: 'show-progress-bar',
          mainText: 'Progress bar',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      SHOW_NO_ANSWER: {
        keyPath: 'showNoAnswer',
        props: {
          id: 'show-no-answer',
          mainText: NO_ANSWER_LABEL,
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      SHOW_GROUP_INFO: {
        keyPath: 'showGroupInfo',
        props: {
          id: 'show-group-info',
          groupName: 'show-group-info',
          mainText: 'Group name and description',
          childComponent: CheckboxRadio,
          optionClassName: '',
          options: [
            { label: 'Group name', value: 'showGroupName' },
            { label: 'Description', value: 'showGroupDescription' },
          ],
          noPermissionDisabled: true,
        },
      },
      SHOW_QNUM_CODE_INFO: {
        keyPath: 'showQNumCode',
        props: {
          id: 'show-qnum-code',
          groupName: 'show-qnum-code',
          mainText: 'Question number and code',
          childComponent: CheckboxRadio,
          optionClassName: '',
          options: [
            { label: 'Question number', value: 'showNumber' },
            { label: 'Code', value: 'showCode' },
          ],
          noPermissionDisabled: true,
        },
      },
      NO_KEYBOARD: {
        keyPath: 'noKeyboard',
        props: {
          id: 'no-keyboard',
          mainText: 'On-screen keyboard',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      QUESTION_INDEX: {
        keyPath: 'questionIndex',
        props: {
          id: 'question-index',
          mainText: 'Question index',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'Enabled', value: 2 },
            { name: 'Disabled', value: 0 },
            { name: 'Incremental', value: 1 },
          ],
          noPermissionDisabled: true,
        },
      },
    },
  },
  LANGUAGE_DISPLAY: {
    title: 'Language specific display',
    settings: {
      LANGUAGE_HELPER: {
        keyPath: 'languageHelper',
        helperSetting: true,
        selectOptions: (globalStates) => {
          const { languages } = globalStates[STATES.SITE_SETTINGS]
          const { survey } = globalStates[STATES.SURVEY]

          const surveyLanguages = survey.language
            .concat(` ${survey.additionalLanguages}`)
            .split(' ')

          return surveyLanguages.map((option) => {
            let addOn = option === survey.language ? ' (base language)' : ''
            let languageOption = {
              value: option,
              label: languages
                ? languages[option]?.description + addOn
                : 'no data available',
            }

            return languageOption
          })
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelper =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]

          return languageHelper ? languageHelper : survey.language
        },
        props: {
          id: 'presentation-language',
          mainText: 'Language',
          childComponent: Select,
          noPermissionDisabled: true,
          childOnNewLine: true,
          dataTestId: 'presentation-language',
        },
      },
      DATE_FORMAT: {
        //DROPDOWN default is based on selected lang...
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.dateFormat',
        props: {
          childComponent: Select,
          childOnNewLine: true,
          mainText: 'Date format',
          id: 'date-format',
          placeholder: 'DD/MM/YYYY',
          noPermissionDisabled: true,
        },
        selectOptions: (globalStates) => {
          const { dateFormats } = globalStates[STATES.SITE_SETTINGS]
          return Object.keys(dateFormats).map((index) => {
            return {
              value: parseInt(index),
              label: dateFormats[index]['dateformat'],
            }
          })
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              dateFormat: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { dateFormat: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]

          return getSettingValueFromSurvey(
            survey,
            PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings.DATE_FORMAT,
            languageHelperValue
          )
        },
      },
      NUMBER_FORMAT: {
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.numberFormat',
        props: {
          id: 'number-format',
          mainText: 'Decimal mark',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'Dot (.)', value: 0 },
            { name: 'Comma (,)', value: 1 },
          ],
          noPermissionDisabled: true,
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              numberFormat: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { numberFormat: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]

          return getSettingValueFromSurvey(
            survey,
            PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings.NUMBER_FORMAT,
            languageHelperValue
          )
        },
      },
      ALIAS: {
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.alias',
        props: {
          id: 'alias',
          mainText: 'Survey alias',
          childOnNewLine: true,
          childComponent: Input,
          noPermissionDisabled: true,
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              alias: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { alias: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings
                .LANGUAGE_HELPER.keyPath
            ]
          return getSettingValueFromSurvey(
            survey,
            PresentationSettingsBlocks.LANGUAGE_DISPLAY.settings.ALIAS,
            languageHelperValue
          )
        },
      },
    },
  },
  NAVIGATION: {
    title: 'Navigation',
    settings: {
      AUTO_REDIRECT: {
        keyPath: 'autoRedirect',
        props: {
          id: 'auto-load-end-url',
          mainText: 'Automatically load end URL when survey complete',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      ALLOW_PREV: {
        keyPath: 'allowPrev',
        props: {
          id: 'allow-backward-navigation',
          mainText: 'Allow backward navigation',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      DELAY_TOGGLE: {
        keyPath: 'delayToggle',
        helperSetting: true,
        props: {
          id: 'delay-toggle',
          mainText: 'Navigation delay',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const delayDuration = getSettingValueFromSurvey(
            survey,
            PresentationSettingsBlocks.NAVIGATION.settings.NAVIGATION_DELAY
          )

          return delayDuration > 0
        },
      },
      NAVIGATION_DELAY: {
        keyPath: 'navigationDelay',
        props: {
          id: 'navigation-delay',
          mainText: 'Navigation delay duration (in seconds)',
          childComponent: Input,
          noPermissionDisabled: true,
          type: 'number',
        },
        linkedSettingsHandler: {
          get linkedSettings() {
            return [PresentationSettingsBlocks.NAVIGATION.settings.DELAY_TOGGLE]
          },
          getUpdateValue: (
            value,
            previousLinkedSettingValue,
            currentLinkedSettingValue
          ) => {
            if (!currentLinkedSettingValue) {
              return 0
            } else if (currentLinkedSettingValue && value === 0) {
              return 10
            }

            return ignoreUpdate
          },
        },
        condition: {
          render: {
            get settings() {
              return [
                PresentationSettingsBlocks.NAVIGATION.settings.DELAY_TOGGLE,
              ]
            },
            check: ([delayToggle]) => {
              return delayToggle.value
            },
          },
        },
      },
      PRINT_ANSWERS: {
        keyPath: 'printAnswers',
        props: {
          id: 'print-answers',
          mainText: 'Participants may print answers',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
    },
    BLOCK_PUBLIC_STATS: {
      blockTitle: 'Public statistics',
      PUBLIC_STATS: {
        keyPath: 'publicStatistics',
        props: {
          id: 'public-statistics',
          mainText: 'Public statistics',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      PUBLIC_GRAPHS: {
        keyPath: 'publicGraphs',
        props: {
          id: 'public-graphs',
          mainText: 'Show graphs in public statistics',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
    },
  },
}
